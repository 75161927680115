import styles from './AnimatedCardLabel.module.scss';

interface Props {
  label: string;
}

const AnimatedCardLabel = ({ label }: Props) => {
  return label ? <div className={styles.label}>{label}</div> : <></>;
};

export default AnimatedCardLabel;
