'use client';
import cn from 'classnames';
import { useTranslations } from 'next-intl';

import { GameSliderType } from '@/src/domain/games/GameSliderType';
import { Link } from '@/src/i18n/routing';

import Button from '../../Button';
import { Typography } from '../../Typography';

import styles from './SlideContentAnimatedList.module.scss';

interface Props {
  cards: GameSliderType[];
  activeIdx: number;
}

const SlideContentAnimatedList = ({ cards, activeIdx }: Props) => {
  const t = useTranslations();

  return (
    <div className={styles.list_container}>
      <ul className={styles.list} style={{ transform: `translateY(-${activeIdx * 250}px)` }}>
        {cards.map((card, idx) => (
          <li
            key={card.id}
            className={cn(styles.list__item, { [styles['list__item--active']]: activeIdx === idx })}
          >
            <div className={styles.list__item_text_block}>
              {!!card.title && (
                <Typography.Title level={3} className={styles.list__item_text_block__title}>
                  {card.title}
                </Typography.Title>
              )}
              {!!card.category.title && (
                <Typography.Paragraph className={styles.list__item_text_block__category}>
                  {card.category.title}
                </Typography.Paragraph>
              )}
            </div>
            {!!card.link && (
              <Link href={card.link} className={styles.list__item_link}>
                <Button text={t('buttons.play_now')} className={styles.list_button} />
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SlideContentAnimatedList;

// 197
