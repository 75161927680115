/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useMemo, useState, useRef } from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { useInterval } from 'react-interval-hook';

import skeletonImage3 from '@/public/images/slotMachines/3D/neostar/1_00025.png';
import skeletonImage1 from '@/public/images/slotMachines/3D/nevada/1_00025.png';
import skeletonImage2 from '@/public/images/slotMachines/3D/riogrande/1_00025.png';
import useContentInView from '@/src/hooks/useContentInView';
import useImageLoad from '@/src/hooks/useImageLoad';

import { DefaultSlotMachinesType } from './interfaces';

import CardSkeleton from '../CardSkeleton';

import styles from './Image3DRotator.module.scss';

const skeletonImages = {
  nevada: skeletonImage1,
  riogrande: skeletonImage2,
  neostar: skeletonImage3,
};

interface IImage3DRotatorProps {
  images: DefaultSlotMachinesType | Array<string>;
  autoRotation?: boolean;
  shortAnimation?: boolean;
  draggable?: boolean;
  mouseMoveAnimation?: boolean;
  onImagesLoaded?: () => void;
}

function Image3DRotator({
  images,
  autoRotation = true,
  shortAnimation,
  mouseMoveAnimation = false,
  draggable = true,
  onImagesLoaded,
}: IImage3DRotatorProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentIdx, setCurrentIdx] = useState(25);
  const isDefaultImages = useMemo(() => typeof images === 'string', [images]);
  const maxIndex = useMemo(() => (shortAnimation ? 30 : 100), [shortAnimation]);
  const minIndex = useMemo(() => (shortAnimation ? 20 : 0), [shortAnimation]);
  const [prevMousePosition, setPrevMousePosition] = useState<number>(0);

  const { isImagesLoaded, onImageLoad, reloadTrigger } = useImageLoad(
    isDefaultImages ? (shortAnimation ? maxIndex - minIndex : 101) : images.length,
  );
  const [isRenderImages, setRenderImages] = useState(false);
  const { onViewTriggerRef } = useContentInView({ action: () => setRenderImages(true) });

  useEffect(() => {
    isImagesLoaded && onImagesLoaded && onImagesLoaded();
  }, [isImagesLoaded]);

  useEffect(() => {
    images && reloadTrigger();
  }, [images]);

  // if mouseMoveAnimation --> generate mouseMove animations (change img on mouseOver action)
  useEffect(() => {
    if (mouseMoveAnimation) {
      const onMouseOver = debounce(function (e) {
        const currentMousePosition = e.pageX;
        if (
          prevMousePosition - currentMousePosition > 5 ||
          prevMousePosition - currentMousePosition < -5
        ) {
          setPrevMousePosition(currentMousePosition);
        }
        setCurrentIdx(prev => {
          if (
            prevMousePosition >= currentMousePosition &&
            prevMousePosition - currentMousePosition > 5
          ) {
            return (isDefaultImages ? prev < maxIndex : images[prev + 1]) ? prev + 1 : prev;
          } else if (
            prevMousePosition <= currentMousePosition &&
            currentMousePosition - prevMousePosition > 5
          ) {
            return prev > minIndex ? prev - 1 : isDefaultImages ? prev : images.length - 1;
          } else {
            return prev;
          }
        });
      }, 3);
      containerRef?.current && containerRef.current.addEventListener('mousemove', onMouseOver);

      return () =>
        containerRef.current
          ? containerRef.current.removeEventListener('mousemove', onMouseOver)
          : undefined;
    }
  }, [prevMousePosition]);

  const autoRotate = () => {
    return setCurrentIdx(prev =>
      (isDefaultImages ? prev < maxIndex : images[prev + 1]) ? prev + 1 : minIndex,
    );
  };

  const { start, stop } = useInterval(autoRotate, 30, {
    autoStart: autoRotation,
    immediate: false,
    selfCorrecting: false,
  });

  // handle drag action
  const onDragMove = (e: DraggableEvent, data: DraggableData) => {
    e.stopPropagation();
    setCurrentIdx(prev => {
      if (data.lastX > data.x) {
        return (isDefaultImages ? prev < maxIndex : images[prev + 1]) ? prev + 1 : minIndex;
      } else {
        return prev > minIndex ? prev - 1 : isDefaultImages ? maxIndex : images.length - 1;
      }
    });
  };

  // if autorotaion --> start auto rotation after render
  useEffect(() => {
    if (!autoRotation) {
      stop();
      setCurrentIdx(25);
    } else if (autoRotation) {
      start();
    }
  }, [autoRotation]);

  return (
    <>
      <Draggable
        axis="none"
        handle=".handle"
        onDrag={draggable ? onDragMove : () => {}}
        onMouseDown={() => {
          autoRotation && stop();
        }}
        grid={[3, 0]}
        scale={1}
        onStop={() => {
          autoRotation && start();
        }}
      >
        <div
          ref={containerRef}
          className={cn(styles.rotatorContainer, 'handle', {
            [styles.rotatorContainer__draggable]: draggable,
          })}
        >
          <div className={styles.viewBox} ref={onViewTriggerRef} />
          {!isImagesLoaded && (
            <div className={styles.skeletonImage}>
              <CardSkeleton
                background={typeof images === 'string' ? skeletonImages[images] : null}
              />
            </div>
          )}
          {isRenderImages &&
            Array(typeof images === 'string' ? 101 : images.length)
              .fill('')
              .map((_, idx) =>
                idx >= minIndex && idx <= maxIndex ? (
                  <img
                    key={`idx_${idx}`}
                    src={
                      typeof images === 'string'
                        ? `/images/slotMachines/3D/${images}/1_00${idx < 100 ? '0' : ''}${
                            idx < 10 ? '0' : ''
                          }${idx}.png`
                        : images[idx]
                    }
                    style={{ opacity: currentIdx === idx ? 1 : 0 }}
                    alt={`slot_${idx}`}
                    onLoad={() => onImageLoad()}
                  />
                ) : null,
              )}
        </div>
      </Draggable>
    </>
  );
}

export default Image3DRotator;
