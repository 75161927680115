'use client';
import { SwiperOptions } from 'swiper';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';

import GameCard from '@/src/components/GameCard';
import { GameType } from '@/src/domain/games/game/GameType';
import useWindowSize, { widthBreakpoints } from '@/src/hooks/useResize';

import ViewAllGamesButton from '../ViewAllGamesButton';

import styles from './NewGamesList.module.scss';

interface Props {
  games: GameType[];
}

const pagination: PaginationOptions = {
  clickable: true,
  renderBullet: function (index, className) {
    return `<span class="${className} custom-pagination-bullet"></span>`;
  },
};

const NewGamesList = ({ games }: Props) => {
  const breakpoints: Record<string | number, SwiperOptions> = {
    [widthBreakpoints.tablet_l + 1]: {
      spaceBetween: 40,
    },
    [widthBreakpoints.mobile_xl + 1]: {
      spaceBetween: 24,
    },
  };

  return (
    <>
      <Swiper
        className={styles.swiper}
        slidesPerView="auto"
        touchEventsTarget="container"
        spaceBetween={20}
        centerInsufficientSlides
        breakpoints={breakpoints}
        modules={[Pagination]}
        pagination={pagination}
      >
        {games?.map(game => (
          <SwiperSlide key={game.id} className={styles.swiper__slide}>
            <GameCard {...game} imageClassName={styles.card_image} />
          </SwiperSlide>
        ))}
      </Swiper>

      <ViewAllGamesButton />
    </>
  );
};

export default NewGamesList;
