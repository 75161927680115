import { MainPageAnimatedCardType } from '@/src/domain/main/MainPageAnimatedCardType';
import { Link } from '@/src/i18n/routing';

import AnimatedCardLabel from '../AnimatedCardLabel';

import styles from './VideoCard.module.scss';
// import { useState } from 'react';
// import { Picture } from '../../Picture';

const VideoCard = ({ tag, image, video, link, button_text }: MainPageAnimatedCardType) => {
  // const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={styles.card}>
      <Link href={link}>
        <AnimatedCardLabel label={tag} />
        <video
          poster={image?.desktop?.webp_x1 || ''}
          src={video}
          className={styles.card__video}
          // onCanPlay={() => setIsLoaded(true)}
          // webkit-playsinline
          playsInline
          autoPlay
          muted
          loop
        />
        {/* {!isLoaded && image && (
            <Picture
              data={image}
              alt="Card's preview"
              containerClassName={styles.card__image_container}
            />
          )} */}
        {button_text && (
          <div className={styles.card_actions}>
            <div className={styles.card_actions_button}>{button_text}</div>
          </div>
        )}
      </Link>
    </div>
  );
};

export default VideoCard;
