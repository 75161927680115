import cn from 'classnames';
import Image, { StaticImageData } from 'next/image';

import styles from './CardSkeleton.module.scss';

const CardSkeleton = ({
  background,
  className = '',
}: {
  background: StaticImageData | null;
  className?: string;
}) =>
  background ? (
    <div className={cn(styles.card_skeleton, className)}>
      <div className={styles.card_skeleton__cover} />
      <Image
        className={styles.card_skeleton__image}
        src={background}
        alt="skeleton-image"
        quality={5}
        loading="lazy"
      />
    </div>
  ) : (
    <></>
  );

export default CardSkeleton;
