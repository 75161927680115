'use client';

import React, { Suspense } from 'react';

import cn from 'classnames';
import { nanoid as uuid } from 'nanoid';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions, SwiperOptions } from 'swiper/types';

// import GameCard from '@/src/components/common/GameCard';
// import { GameType } from '@/src/domain/games/game/GameType';
import ArrowLeft from '@/assets/Icons/ArrowLeft';
import { widthBreakpoints } from '@/src/hooks/useResize';
import { Link } from '@/src/i18n/routing';

import Image3DRotator from '../Image3DRotator';
import { DefaultSlotMachinesType } from '../Image3DRotator/interfaces';

import styles from './SlotMachinesSlider.module.scss';

const breakpoints: Record<string | number, SwiperOptions> = {
  [widthBreakpoints.desktop_s + 1]: {
    slidesPerView: 3,
    spaceBetween: 32,
  },
  [widthBreakpoints.tablet_l + 1]: {
    slidesPerView: 1,
    spaceBetween: 32,
  },
  [widthBreakpoints.mobile_xl + 1]: {
    slidesPerView: 1,
    spaceBetween: 20,
  },

  [widthBreakpoints.mobile_m + 1]: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  [200]: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
};

interface Props {
  isCenterInsufficientSlides?: boolean;
}

const pagination: PaginationOptions = {
  clickable: true,
  renderBullet: function (index, className) {
    return '<span class="' + className + '"></span>';
  },
};

const cards: Array<{ slug: DefaultSlotMachinesType; title: string }> = [
  {
    slug: 'nevada',
    title: 'Nevada',
  },
  {
    slug: 'neostar',
    title: 'Neostar',
  },
  {
    slug: 'riogrande',
    title: 'RioGrande',
  },
];

const SlotMachinesSlider = ({ isCenterInsufficientSlides }: Props) => {
  return (
    <Swiper
      className={styles.slider}
      breakpoints={breakpoints}
      modules={[Pagination, Navigation]}
      pagination={pagination}
      centerInsufficientSlides={isCenterInsufficientSlides}
      slidesPerView={2}
    >
      {cards?.map(item => (
        <SwiperSlide key={uuid()} className={styles.swiperSlide}>
          <Link href={`slot-machines/${item.slug}`} key={uuid()}>
            <div className={styles.swiperSlide__image}>
              <Image3DRotator
                images={item.slug}
                autoRotation={false}
                shortAnimation
                draggable={false}
                mouseMoveAnimation
              />

              <div className={cn(styles.swiperSlide__labelLink, styles.swiperLabel)}>
                {item.title}
                {/* {currentSize <= 1280 ? ( */}
                <div className={styles.swiperSlide__labelLink_rightArrow}>
                  <ArrowLeft width="15" height="13" />
                </div>
                {/* ) : null} */}
              </div>
            </div>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SlotMachinesSlider;
