import { useRef } from 'react';

import cn from 'classnames';

import { CrossIcon } from '@/assets/Icons';

import styles from './VideoHTMLPlayer.module.scss';

interface Props {
  isFrameActive: boolean;
  toggleFrame: () => void;
  src?: string;
}

const VideoHTMLPlayer = ({
  isFrameActive,
  toggleFrame,
  src = '/videos/belatra-intro.mp4',
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const onVideoLoaded = () => {
    videoRef.current?.play();
  };

  return (
    <section>
      <button className={styles.frame__close}>
        <CrossIcon stroke="#0041ff" />
      </button>
      <div
        className={cn(styles.background, { [styles['background--active']]: isFrameActive })}
        role="button"
        tabIndex={1}
        onClick={toggleFrame}
      />
      <div
        className={cn(styles.frame, {
          [styles['frame--played']]: isFrameActive,
        })}
      >
        <video
          ref={videoRef}
          onCanPlay={onVideoLoaded}
          onClick={e => e.stopPropagation()}
          src={src}
          className={styles.frame__video}
          controls
        />
      </div>
    </section>
  );
};

export default VideoHTMLPlayer;
