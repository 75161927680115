import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AnimatedBlock/AnimatedBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/GameVideoSlider/GameVideoSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainPage/components/AnimatedCardsSection/AnimatedCardsSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/MainPage/components/GetInTouchSection/GetInTouchSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/MainPage/components/MainDescription/MainDescription.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/BackgroundDivider/BackgroundDivider.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainPage/components/NewGamesList/NewGamesList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/MainPage/components/ProviderEntertainmentSection/ProviderEntertainmentSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/GameFiltersSection/GameFiltersSection.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MainPage/components/VideoSection/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/SlotMachinesSection/SlotMachinesSection.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/MainPage/MainPage.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SlotMachinesSlider/SlotMachinesSlider.tsx");
