'use client';
import { useEffect, useRef, useState } from 'react';

import Image from 'next/image';

import { PlayButtonIcon } from '@/assets/Icons';
import videoBannerImage from '@/public/images/main-page/video-banner.webp';
import BackgroundDivider from '@/src/components/BackgroundDivider';
import VideoHTMLPlayer from '@/src/components/VideoHTMLPlayer';

import styles from './VideoSection.module.scss';

const VideoSection = ({ withoutDivider }: { withoutDivider?: boolean }) => {
  const [isFrameActive, setIsFrameActive] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const toggleFrame = () => {
    const video = videoRef?.current;
    document.body.style.overflow = !isFrameActive ? 'hidden' : 'initial';
    setIsFrameActive(!isFrameActive);
    if (video) {
      !isFrameActive ? video.pause() : video.play();
    }
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  return (
    <section className={styles.section}>
      <button className={styles.section__banner} onClick={toggleFrame} aria-label="Play button">
        <div className={styles.desktop}>
          <video
            ref={videoRef}
            src="/videos/belatra-intro-skeleton.mp4"
            className={styles.section__banner_video}
            poster={videoBannerImage.src}
            // webkit-playsinline
            playsInline
            autoPlay
            muted
            loop
          />
        </div>
        <div className={styles.tablet}>
          <Image
            src={videoBannerImage}
            className={styles.section__banner_image}
            alt="banner"
            placeholder="blur"
            width={1013}
            height={570}
          />
        </div>
        <div className={styles.section__banner_play}>
          <PlayButtonIcon />
        </div>
        {isFrameActive ? (
          <VideoHTMLPlayer isFrameActive={isFrameActive} toggleFrame={toggleFrame} />
        ) : (
          <></>
        )}
      </button>
      {!withoutDivider && <BackgroundDivider className={styles.section__divider_line} />}
    </section>
  );
};

export default VideoSection;
