'use client';

import { useTranslations } from 'next-intl';

import { GAMES_APP_ENDPOINT } from '@/src/api/endpoints/app';
import { Link } from '@/src/i18n/routing';

import styles from './ViewAllGamesButton.module.scss';

const ViewAllGamesButton = () => {
  const t = useTranslations();

  return (
    <div className={styles.viewAll}>
      <Link href={GAMES_APP_ENDPOINT} className={styles.viewAll_button}>
        {t('buttons.view_all_games')}
      </Link>
    </div>
  );
};

export default ViewAllGamesButton;
