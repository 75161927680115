'use client';

import React, { ReactNode, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import styles from './AnimationBlock.module.scss';

type Animations = 'reset' | 'hide' | 'fromLeft' | 'toLeft' | 'fromBottom';

type BaseProps = {
  children: ReactNode;
  transition?: number;
  rootMargin?: string;
  delay?: number;
  className?: string;
};

type Props =
  | (BaseProps & {
      animation: Animations;
      animationClass?: never;
    })
  | (BaseProps & {
      animationClass: string;
      animation?: never;
    });

const AnimatedBlock = (props: Props) => {
  const {
    children,
    className,
    rootMargin = '1px',
    delay = 0,
    animation,
    animationClass = '',
  } = props;
  const [complete, setComplete] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const options = { threshold: [0.8], rootMargin };

  useEffect(() => {
    const observer = new IntersectionObserver(onScroll, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);

  const onScroll: IntersectionObserverCallback = e => {
    if (e[0].isIntersecting && !complete) {
      setComplete(true);
    }
  };

  return (
    <div
      ref={ref}
      style={animation ? { animationDelay: `${delay}s` } : {}}
      className={cn(styles.default, className, {
        ...(animation ? { [styles[animation]]: complete } : {}),
        [animationClass]: complete,
      })}
    >
      {children}
    </div>
  );
};

export default AnimatedBlock;
