import { useRef, useEffect } from 'react';

interface IUseContentInView {
  action: () => void;
}

export default function useContentInView(props: IUseContentInView) {
  const { action } = props;
  const onViewTriggerRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const options = {
    threshold: [0.1],
  };
  useEffect(() => {
    observer.current = new IntersectionObserver(e => {
      e[0].isIntersecting && action();
    }, options);
  }, []);

  useEffect(() => {
    if (onViewTriggerRef?.current && observer.current) {
      observer.current.observe(onViewTriggerRef?.current);
    }
  }, [onViewTriggerRef?.current, observer.current]);

  return { onViewTriggerRef };
}
