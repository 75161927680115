import { useState } from 'react';

const useImageLoad = (imagesCount: number) => {
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const isImagesLoaded = loadedImagesCount >= imagesCount;

  const onImageLoad = () => {
    setLoadedImagesCount(prev => ++prev);
  };

  const reloadTrigger = () => {
    setLoadedImagesCount(0);
  };

  return { isImagesLoaded, onImageLoad, reloadTrigger };
};

export default useImageLoad;
