'use client';
import { useState } from 'react';

import cn from 'classnames';
import Image from 'next/image';
import type { Swiper as SwiperType } from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types';

import { GameSliderType } from '@/src/domain/games/GameSliderType';
import useWindowSize, { widthBreakpoints } from '@/src/hooks/useResize';

import SlideContentAnimatedList from './SlideContentAnimatedList';

import { Picture } from '../Picture';
import { Typography } from '../Typography';

import 'swiper/css/effect-fade';
import styles from './GameVideoSlider.module.scss';

interface Props {
  cards: GameSliderType[];
  className?: string;
}

const GameVideoSlider = ({ cards, className }: Props) => {
  const { currentSize } = useWindowSize();
  const [activeSlideIdx, setActiveSlideIdx] = useState(0);
  const activeCard = cards?.[activeSlideIdx];
  const backgroundImage = activeCard?.image;
  const isMobile = currentSize < widthBreakpoints.mobile_xl;

  const pagination: PaginationOptions = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} custom-pagination-bullet">${index + 1}</span>`;
    },
  };

  const onChangeSlide = (swiper: SwiperType) => {
    setActiveSlideIdx(swiper.activeIndex);
  };

  return cards?.length ? (
    <>
      <section className={styles.section}>
        {backgroundImage && (
          <div className={styles.section_background}>
            <Image src={backgroundImage.desktop.webp_x1} layout="fill" alt="background image" />
          </div>
        )}
        <SlideContentAnimatedList cards={cards} activeIdx={activeSlideIdx} />

        <Swiper
          className={cn(styles.slider, className)}
          effect={'fade'}
          modules={[EffectFade, Pagination, Navigation, Autoplay]}
          pagination={pagination}
          slidesPerView={1}
          spaceBetween={40}
          onActiveIndexChange={onChangeSlide}
          speed={650}
          centeredSlides
          //   autoplay={{ delay: 3000 }}
          //   loop={true}
        >
          {cards.map(({ id, video, videoMobile, image, imageMobile, title, category, link }) => (
            <SwiperSlide
              // className={styles.cards__swiper_card}
              key={id}
            >
              <div className={styles.slider__slide}>
                {(!isMobile && video) || (isMobile && videoMobile) ? (
                  <video
                    src={(isMobile ? videoMobile : video) ?? undefined}
                    className={styles.slider__video}
                    autoPlay
                    webkit-playsinline
                    playsInline
                    muted
                    loop
                  />
                ) : (
                  <Picture
                    data={isMobile ? imageMobile : image}
                    alt={title || 'sliders banner'}
                    containerClassName={styles.slider__image_container}
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={cn(
            styles.slider__slide__text_content__text_block,
            styles['slider__slide__text_content__text_block--mobile'],
          )}
        >
          <Typography.Title level={3} className={styles.slider__slide__text_content__title}>
            {cards[activeSlideIdx]?.title}
          </Typography.Title>
          <Typography.Paragraph className={styles.slider__slide__text_content__category}>
            {cards[activeSlideIdx]?.category.title}
          </Typography.Paragraph>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default GameVideoSlider;
